import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  findProducts,
} from "../../../Redux/Customers/Product/Action";
import { API_BASE_URL } from "../../../config/api";


const ProductsTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const { customersProduct } = useSelector((store) => store);
  const [categoryData, setCategoryData] = useState([]);
  const [filterValue, setFilterValue] = useState({
    availability: "",
    category: "",
    sort: "",
  });
  const [categories, setCategories] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  // Query parameters
  const searchParams = new URLSearchParams(location.search);
  const availability = searchParams.get("availability");
  const category = searchParams.get("category");
  const sort = searchParams.get("sort");
  const page = searchParams.get("page") || currentPage;

  useEffect(() => {
    if (customersProduct?.products?.content) {
      if (categories.length === 0) {
        const uniqueCategories = [
          ...new Set(
            customersProduct.products.content.map(
              (item) => item.category[0]?.name
            )
          ),
        ];
        setCategories(uniqueCategories);
      }
    }
  }, [customersProduct.products, categories.length]);

  useEffect(() => {
    const data = {
      category: category || "",
      colors: [],
      sizes: [],
      minPrice: 0,
      maxPrice: 100000,
      minDiscount: 0,
      sort: sort || "price_low",
      pageNumber: currentPage,
      pageSize: pageSize,
      stock: availability,
    };
    dispatch(findProducts(data));
  }, [category, currentPage, pageSize, customersProduct.deleteProduct]);

  const filteredProducts = useMemo(() => {
    let products = [...(customersProduct?.products?.content || [])];

    // Filter by availability
    if (availability === "in_stock") {
      products = products.filter((item) => item.stock_status === "In stock");
    } else if (availability === "out_of_stock") {
      products = products.filter((item) => item.stock_status !== "In Stock");
    }

    // Sort by price
    if (sort === "price_high") {
      products.sort((a, b) => b.discounted_price - a.discounted_price);
    } else if (sort === "price_low") {
      products.sort((a, b) => a.discounted_price - b.discounted_price);
    }

    return products;
  }, [availability, sort, customersProduct?.products?.content]);

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value - 1); // Update current page (zero-based)
    searchParams.set("page", value - 1);
    const query = searchParams.toString();
    navigate({ search: `?${query}` });
  };

  const handleFilterChange = (e, sectionId) => {
    setFilterValue((values) => ({ ...values, [sectionId]: e.target.value }));
    searchParams.set(sectionId, e.target.value);
    const query = searchParams.toString();
    navigate({ search: `?${query}` });
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(0); // Reset to the first page
    searchParams.set("pageSize", e.target.value);
    const query = searchParams.toString();
    navigate({ search: `?${query}` });
  };

  const handleDeleteProduct = (productId) => {
    dispatch(deleteProduct(productId)).then(() => {
      const data = {
        category: category || "",
        colors: [],
        sizes: [],
        minPrice: 0,
        maxPrice: 100000,
        minDiscount: 0,
        sort: sort || "price_low",
        pageNumber: currentPage,
        pageSize: pageSize,
      };
      dispatch(findProducts(data));
    });
  };

  // Calculate total pages based on products content length
  const totalPages = Math.ceil(filteredProducts.length / pageSize);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories/`);
      setCategoryData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Flatten nested categories to get third-level categories
  const getThirdLevelCategories = () => {
    const flattenCategories = (categories, level = 0) => {
      let result = [];
      categories.forEach((category) => {
        if (level === 2) {
          result.push(category);
        } else if (
          category.subcategories &&
          category.subcategories.length > 0
        ) {
          result = result.concat(
            flattenCategories(category.subcategories, level + 1)
          );
        }
      });
      return result;
    };

    return flattenCategories(categoryData, 0);
  };

  const thirdLevelCategories = getThirdLevelCategories();

  return (
    <Box width={"100%"}>
      <Card className="p-3">
        {/* <CardHeader
          title="Sort"
          sx={{
            pt: 0,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
        /> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={filterValue.category}
                label="Category"
                onChange={(e) => handleFilterChange(e, "category")}
              >
                <MenuItem value={""}>All</MenuItem>
                {thirdLevelCategories.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="availability-select-label">
                Availability
              </InputLabel>
              <Select
                labelId="availability-select-label"
                id="availability-select"
                value={filterValue.availability}
                label="Availability"
                onChange={(e) => handleFilterChange(e, "availability")}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"in_stock"}>In Stock</MenuItem>
                <MenuItem value={"out_of_stock"}>Out Of Stock</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="sort-select-label">Sort By Price</InputLabel>
              <Select
                labelId="sort-select-label"
                id="sort-select"
                value={filterValue.sort}
                label="Sort By Price"
                onChange={(e) => handleFilterChange(e, "sort")}
              >
                <MenuItem value={"price_high"}>High - Low</MenuItem>
                <MenuItem value={"price_low"}>Low - High</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      <Card className="mt-2">
        <CardHeader
          title="All Products"
          sx={{
            pt: 2,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
        />
        <div style={{ height: "400px", overflowY: "auto" }}>
          {" "}
          {/* Fixed height and scrollable */}
          <TableContainer>
            <Table sx={{ minWidth: 800 }} aria-label="table in dashboard">
              <TableHead>
                <TableRow>
                  <TableCell>SQ ID</TableCell>
                  <TableCell>Image 1</TableCell>
                  <TableCell>Image 2</TableCell>
                  <TableCell>Image 3</TableCell>
                  <TableCell>Image 4</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Category</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Update</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProducts
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize) // Slice the products for the current page
                  .map((item) => (
                    <TableRow
                      hover
                      key={item.id}
                      sx={{
                        "&:last-of-type td, &:last-of-type th": { border: 0 },
                      }}
                    >
                      <TableCell>{item.Sq_id}</TableCell>
                      <TableCell>
                        <Avatar alt={item.title} src={item.image_url} />
                      </TableCell>
                      <TableCell>
                        <Avatar alt={item.title} src={item.image_url1} />
                      </TableCell>
                      <TableCell>
                        <Avatar alt={item.title} src={item.image_url2} />
                      </TableCell>
                      <TableCell>
                        <Avatar alt={item.title} src={item.image_url3} />
                      </TableCell>
                      <TableCell
                        sx={{
                          py: (theme) => `${theme.spacing(0.5)} !important`,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "0.875rem !important",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography variant="caption">
                            {item.brand}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {item.category[0]?.name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {item.discounted_price}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {item.quantity}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          onClick={() =>
                            navigate(`/admin/product/update/${item.id}`)
                          }
                          variant="text"
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          variant="text"
                          onClick={() => handleDeleteProduct(item.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Card>
      <Card className="mt-2">
        <div className="mx-auto px-4 py-5 flex items-center justify-center shadow-lg rounded-md relative">
          <div className="flex justify-center w-full">
            <Pagination
              count={totalPages || 1}
              color="primary"
              page={currentPage + 1}
              onChange={handlePaginationChange}
              siblingCount={1}
            />
          </div>
          <FormControl className="absolute right-4 w-40">
            <InputLabel id="page-size-select-label">Rows</InputLabel>
            <Select
              labelId="page-size-select-label"
              id="page-size-select"
              value={pageSize}
              label="Rows"
              onChange={handlePageSizeChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Card>
    </Box>
  );
};

export default ProductsTable;
