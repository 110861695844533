import api, { API_BASE_URL } from "../../../config/api";
import {
  updateOrderStatusRequest,
  updateOrderStatusSuccess,
  updateOrderStatusFailure,
  deleteOrderRequest,
  deleteOrderSuccess,
  deleteOrderFailure,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailure,
} from "./ActionCreator";

// Generic function to update order status
export const updateOrderStatus = (jwt, orderId, status) => async (dispatch) => {
  dispatch(updateOrderStatusRequest());
  console.log(jwt, orderId, status);
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await api.post(
      `${API_BASE_URL}/admin/update_status`,
      {
        order_id: orderId,
        order_status: status,
      },
      config
    );
    console.log("update_order_status", response.data);
    dispatch(updateOrderStatusSuccess({ orderId, status }));
  } catch (error) {
    dispatch(updateOrderStatusFailure(error.message));
  }
};

// Function to delete an order
export const deleteOrder = (jwt, orderId) => async (dispatch) => {
  dispatch(deleteOrderRequest());

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const { data } = await api.delete(
      `${API_BASE_URL}/admin/orders/${orderId}/delete`,
      config
    );
    console.log("delete order", data);
    dispatch(deleteOrderSuccess(orderId));
  } catch (error) {
    console.log("catch error", error);
    dispatch(deleteOrderFailure(error.message));
  }
};

// Function to get orders
export const getOrders = (reqData) => async (dispatch) => {
  dispatch(getOrdersRequest());

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${reqData.jwt}`,
      },
    };
    const response = await api.get(
      `${API_BASE_URL}/admin/show_orders/?page=${reqData.page}&page_size=${reqData.pageSize}`,
      config
    );
    console.log("get all orders", response.data);
    dispatch(getOrdersSuccess(response.data));
  } catch (error) {
    console.log("catch error", error);
    dispatch(getOrdersFailure(error.message));
  }
};
