import React, { useState, useEffect } from "react";
import "./Carousel.css";

const Carousel = ({ carouselData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getClassForIndex = (index) => {
    const relativeIndex =
      (index - currentIndex + carouselData.length) % carouselData.length;

    if (relativeIndex === 0) return "active";
    if (relativeIndex === 1) return "right";
    if (relativeIndex === carouselData.length - 1) return "left";
    return "inactive";
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 2000);
    return () => clearInterval(intervalId);
  }, [carouselData.length]);

  return (
    <div className="carousel-container">
      <button className="carousel-button left" onClick={handlePrev}>
        ‹
      </button>
      <div className="carousel">
        {carouselData.map((item, index) => {
          const className = getClassForIndex(index);
          const isOverlay = className === "left" || className === "right";

          return (
            <div
              key={index}
              className={`carousel-item ${className}`}
              style={{
                transform: `translateX(${
                  className === "left"
                    ? "-50%"
                    : className === "right"
                    ? "50%"
                    : "0%"
                })`,
                zIndex:
                  className === "active"
                    ? 3
                    : className === "right" || className === "left"
                    ? 2
                    : 1,
                opacity: className === "inactive" ? 0.5 : 1,
              }}
            >
              <a href={item.path}>
                <img src={item.image} alt={`Slide ${index + 1}`} />
              </a>
              {isOverlay && <div className="overlay"></div>}
            </div>
          );
        })}
      </div>
      <button className="carousel-button right" onClick={handleNext}>
        ›
      </button>
    </div>
  );
};

export default function App() {
  const homeCarouselData = [
    {
      image: "https://qalakarwaan.in/images/image1.png",
      path: "/Home Appliances/Cups/Ceramic Cups",
    },
    {
      image: "https://qalakarwaan.in/images/image2.png",
      path: "/Home Appliances/Cups/Ceramic Cups",
    },
    {
      image: "https://qalakarwaan.in/images/image3.png",
      path: "/Home Appliances/Cups/Ceramic Cups",
    },
    {
      image: "https://qalakarwaan.in/images/image2.png",
      path: "/Home Appliances/Cups/Ceramic Cups",
    },
  ];

  return <Carousel carouselData={homeCarouselData} />;
}
