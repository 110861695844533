import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  Menu,
  Pagination,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateOrderStatus,
  deleteOrder,
  getOrders,
} from "../../../Redux/Admin/Orders/Action";

const OrdersTable = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ status: "", sort: "" });
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const { adminsOrder } = useSelector((store) => store);
  const [anchorElArray, setAnchorElArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  useEffect(() => {
    dispatch(getOrders({ jwt, page: currentPage, pageSize }));
  }, [jwt, currentPage, pageSize, dispatch]);

  const handleUpdateStatusMenuClick = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleUpdateStatusMenuClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleUpdateOrderStatus = (orderId, status, index) => {
    handleUpdateStatusMenuClose(index);
    dispatch(updateOrderStatus(jwt, orderId, status));
  };

  const handleDeleteOrder = (orderId) => {
    handleUpdateStatusMenuClose();
    dispatch(deleteOrder(orderId));
  };

  // Safeguard: Check if orders data is available
  const ordersData = adminsOrder.orders ? adminsOrder.orders.orders : [];

  // Apply filters and sorting if orders data is available
  const filteredAndSortedOrders = Array.isArray(ordersData)
    ? ordersData
        .filter((order) => {
          // Filter by status if selected
          return (
            formData.status === "" || order.order_status === formData.status.toLowerCase()
          );
        })
        .sort((a, b) => {
          // Sort by date
          if (formData.sort === "Newest") {
            return new Date(b.created_at) - new Date(a.created_at);
          } else if (formData.sort === "Older") {
            return new Date(a.created_at) - new Date(b.created_at);
          }
          return 0; // No sorting if sort is not selected
        })
    : [];

  return (
    <Box>
      <Card className="p-3">
        <CardHeader
          title="Sort"
          sx={{
            pt: 0,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={formData.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"PLACED"}>PLACED</MenuItem>
                <MenuItem value={"CONFIRMED"}>CONFIRMED</MenuItem>
                <MenuItem value={"DELIVERED"}>DELIVERED</MenuItem>
                <MenuItem value={"CANCELED"}>CANCELED</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="sort"
                value={formData.sort}
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value={"Newest"}>Newest</MenuItem>
                <MenuItem value={"Older"}>Older</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Card className="mt-2">
        <CardHeader
          title="All Orders"
          sx={{
            pt: 2,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 800 }} aria-label="table in dashboard">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Total Items</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Update</TableCell>
                {/* <TableCell>Delete</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedOrders.length > 0 ? (
                filteredAndSortedOrders.map((item, index) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-of-type td, &:last-of-type th": { border: 0 },
                    }}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.user_id}</TableCell>
                    <TableCell>{item.total_item}</TableCell>
                    <TableCell>{item.total_price}</TableCell>
                    <TableCell>{item.created_at}</TableCell>
                    <TableCell>
                      <Chip
                        sx={{
                          color: "white !important",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                        label={item.order_status}
                        size="small"
                        color={
                          item.order_status === "pending"
                            ? "info"
                            : item.order_status === "delivered"
                            ? "success"
                            : "secondary"
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        id={`basic-button-${item.id}`}
                        aria-controls={`basic-menu-${item.id}`}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorElArray[index])}
                        onClick={(event) =>
                          handleUpdateStatusMenuClick(event, index)
                        }
                      >
                        Status
                      </Button>
                      <Menu
                        id={`basic-menu-${item.id}`}
                        anchorEl={anchorElArray[index]}
                        open={Boolean(anchorElArray[index])}
                        onClose={() => handleUpdateStatusMenuClose(index)}
                        MenuListProps={{
                          "aria-labelledby": `basic-button-${item.id}`,
                        }}
                      >
                        <MenuItem
                          onClick={() =>
                            handleUpdateOrderStatus(item.id, "confirmed", index)
                          }
                          disabled={
                            item.order_status === "delivered" ||
                            item.order_status === "shipped" ||
                            item.order_status === "confirmed"
                          }
                        >
                          CONFIRMED ORDER
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleUpdateOrderStatus(item.id, "shipped", index)
                          }
                          disabled={
                            item.order_status === "delivered" ||
                            item.order_status === "shipped"
                          }
                        >
                          SHIPPED ORDER
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleUpdateOrderStatus(item.id, "delivered", index)
                          }
                        >
                          DELIVERED ORDER
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        onClick={() => handleDeleteOrder(item.id)}
                        variant="text"
                      >
                        delete
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No orders found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card className="mt-2 flex justify-center p-4">
        <Pagination
          page={currentPage}
          onChange={handlePaginationChange}
          count={Math.ceil(adminsOrder.orders?.totalOrders / pageSize)}
          color="primary"
        />
      </Card>
    </Box>
  );
};

export default OrdersTable;
