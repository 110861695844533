export const homeCarouselData = [
  {
    image: "https://qalakarwaan.in/images/image1.png",
    path: "/Home Appliances/Cups/Ceramic Cups",
  },
  {
    image: "https://qalakarwaan.in/images/image2.png",
    path: "/Home Appliances/Cups/Ceramic Cups",
  },
  {
    image: "https://qalakarwaan.in/images/image3.png",
    path: "/Home Appliances/Cups/Ceramic Cups",
  },
  {
    image: "https://qalakarwaan.in/images/image2.png",
    path: "/Home Appliances/Cups/Ceramic Cups",
  },
];
