import React, { useState } from "react";
import { navigation } from "../../../config/navigationMenu";

const DepartmentsMenu = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(
    navigation.categories[0].sections[0].id
  );

  const handleDepartmentChange = (id) => {
    setSelectedDepartment(id);
  };

  const handleItemClick = (href) => {
    window.location.href = href;
  };

  return (
    <div className="flex flex-col md:flex-row ml-[3%] mr-[3%]">
      {/* Sidebar */}
      <div className="w-full md:w-1/6 p-2 md:p-4 border-b md:border-b-0 md:border-r bg-gray-100">
        <h2 className="font-bold text-lg mb-4">
          {navigation?.categories[0].name}
        </h2>
        <ul>
          {navigation?.categories[0].sections.map((section) => (
            <li
              key={section.id}
              className={`cursor-pointer py-2 ${
                selectedDepartment === section.id
                  ? "text-purple-600 font-semibold"
                  : ""
              }`}
              onClick={() => handleDepartmentChange(section.id)}
            >
              {section.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="w-full md:w-5/6 p-2 md:p-4">
        <h2 className="font-bold text-2xl mb-4">
          {
            navigation?.categories[0].sections.find(
              (section) => section.id === selectedDepartment
            )?.name
          }
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          {navigation?.categories[0].sections
            .find((section) => section.id === selectedDepartment)
            ?.items.map((item) => (
              <div
                key={item.id}
                className="flex flex-col items-center text-center cursor-pointer"
                onClick={() => handleItemClick(item.href)}
              >
                <img
                  src={`${item.img}`}
                  alt={item.name}
                  className="w-full h-40 object-cover rounded-lg mb-2"
                />
                <p className="text-lg font-semibold">{item.name}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DepartmentsMenu;
