import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import HomeProductCard from "./HomeProductCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./HomeProductSection.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../config/api";

const HomeProductSection = ({ section, category }) => {
  const [products, setProducts] = useState([]);
  const [showNavigation, setShowNavigation] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/products?color=&minPrice=0&maxPrice=10000&minDiscount=0&category=${encodeURIComponent(
            category
          )}&pageSize=10`
        );
        const data = await response.json();
        setProducts(data?.content || []);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (category) {
      fetchData();
    }
  }, [category]);

  useEffect(() => {
    const handleResize = () => {
      setShowNavigation(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial load

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      {/* Section Title */}
      <h2 className="text-2xl font-extrabold text-gray-900 py-5">{section}</h2>

      <div className="relative">
        {/* Product Carousel using Swiper */}
        <Swiper
          modules={[Navigation, Pagination]} // Enables navigation and pagination
          spaceBetween={10} // Spacing between slides
          breakpoints={{
            320: { slidesPerView: 1.4 },
            480: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 5 },
            1440: { slidesPerView: 6 },
            1600: { slidesPerView: 7 },
            1920: { slidesPerView: 8 },
          }}
          navigation={showNavigation}
          // pagination={{ clickable: true }}
          className="mySwiper"
        >
          {products?.slice(0, 10).map((item, index) => (
            <SwiperSlide key={index}>
              <HomeProductCard product={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeProductSection;
