import React from "react";
import HomeCarousel from "../customer/Components/Carousel/HomeCarousel";
import Offers from "../customer/Components/Offers/Offers";
import Bestsellers from "../customer/Components/Bestsellers/bestsellers"
import Department from "../customer/Components/Department/department";
import { homeCarouselData } from "../customer/Components/Carousel/HomeCaroselData";
import HomeProductSection from "../customer/Components/Home/HomeProductSection";

const Homepage = () => {
  return (
    <div className="">
      
      <HomeCarousel images={homeCarouselData} />

      <div className="space-y-10 py-20">
        <Bestsellers />
        <HomeProductSection category={"ceramic cups"} section={"Ceramic Coffee Cups"} />
        <HomeProductSection category={"rugs"} section={"Rugs"} />
        <Department />
      </div>
    </div>
  );
};

export default Homepage;
