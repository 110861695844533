export const FIND_PRODUCTS_BY_CATEGORY_REQUEST = 'FIND_PRODUCTS_BY_CATEGORY_REQUEST';
export const FIND_PRODUCTS_BY_CATEGORY_SUCCESS = 'FIND_PRODUCTS_BY_CATEGORY_SUCCESS';
export const FIND_PRODUCTS_BY_CATEGORY_FAILURE = 'FIND_PRODUCTS_BY_CATEGORY_FAILURE';


export const FIND_PRODUCT_BY_ID_REQUEST = 'FIND_PRODUCT_BY_ID_REQUEST';
export const FIND_PRODUCT_BY_ID_SUCCESS = 'FIND_PRODUCT_BY_ID_SUCCESS';
export const FIND_PRODUCT_BY_ID_FAILURE = 'FIND_PRODUCT_BY_ID_FAILURE';


export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const SEARCH_PRODUCTS_REQUEST = "SEARCH_PRODUCTS_REQUEST";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE";
