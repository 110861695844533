import React from "react";

const AddressCard = ({address}) => {
  console.log(address);
  return (
    <div>
      {/* <h1 className="text-lg font-semibold py-4">Delivery Adress</h1> */}
      <div className="space-y-3">
        <p className="font-semibold">{`${address?.first_name} ${address?.last_name}`}</p>

        <p>
          {`${address?.street_address} ${address?.city} ${address?.state},  ${address?.zip_code}`}
        </p>

        <div className="space-y-1">
          <p className="font-semibold">Phone Number</p>
          <p>{address?.mobile}</p>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
