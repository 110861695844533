import { Grid, Link, Typography, Box, Divider } from "@mui/material";
import { Phone, HelpOutline, MailOutline } from "@mui/icons-material";
import { Facebook, Instagram } from "@mui/icons-material";
import Twitter from "@mui/icons-material/Twitter";

const Footer = () => {
  const brandTextStyle = {
    fontFamily: '"playfair display", serif',
    fontSize: "1.25rem",
    fontWeight: "700",
    color: "#2C3E50",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
  };

  return (
    <Box sx={{ bgcolor: "#f8f8f8", py: 3, mt: 5 }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={4} md={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Phone sx={{ mr: 1 }} />
            <Typography variant="body2">Talk to us</Typography>
          </Box>
          <Typography align="center" variant="body2">
            1800-111-1111
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <HelpOutline sx={{ mr: 1 }} />
            <Typography variant="body2">Helpcentre</Typography>
          </Box>
          <Typography align="center" variant="body2">
            <Link
              href="https://qalakarwaan.in/help"
              color="inherit"
              underline="always"
            >
              QalaKarwaan.in/help
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <MailOutline sx={{ mr: 1 }} />
            <Typography variant="body2">Write to us</Typography>
          </Box>
          <Typography align="center" variant="body2">
            help@qalakarwaan.in
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          display="flex"
          justifyContent="center"
        >
          <Link href="https://www.facebook.com" color="inherit" sx={{ mx: 1 }}>
            <Facebook />
          </Link>
          <Link href="https://www.x.com" color="inherit" sx={{ mx: 1 }}>
            <Twitter /> {/* Substitute for X */}
          </Link>
          <Link href="https://www.instagram.com" color="inherit" sx={{ mx: 1 }}>
            <Instagram />
          </Link>
        </Grid>
      </Grid>

      {/* Divider to separate the upper and lower grid */}
      <Divider sx={{ my: 4 }} />

      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={4}
          textAlign={{ xs: "center", md: "left" }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Link href="/" sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://blackspeed.in/qala%20kawraan_logo_page-0001.jpg"
              alt="ShopWithQalaKarwaan"
              className="h-8 w-8 mr-2"
              style={{ height: "2rem", width: "2rem", marginRight: "0.5rem" }}
            />
            <Typography variant="h6" sx={brandTextStyle}>
              Qala Karwaan
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "center" }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Link href="#" underline="none" color="inherit">
              Terms & Conditions
            </Link>{" "}
            -{" "}
            <Link href="#" underline="none" color="inherit">
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
