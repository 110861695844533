import * as React from "react";
import axios from "axios";
import { Grid, TextField, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../../Redux/Customers/Order/Action";
import AddressCard from "../adreess/AdreessCard";
import { useState } from "react";
import { API_BASE_URL } from "../../../config/api";
import toast, { Toaster } from 'react-hot-toast';

export default function AddDeliveryAddressForm({ handleNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const { auth } = useSelector((store) => store);
  const [selectedAddress, setSelectedAdress] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newAddress = {
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      street_address: data.get("street_address"),
      city: data.get("city"),
      state: data.get("state"),
      zip_code: data.get("zip_code"),
      mobile: data.get("mobile"),
    };

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    };
    const addressId = await axios.put(`${API_BASE_URL}/add_address`, newAddress, config);
    console.log(addressId.data);
    const address = {
      id: addressId.data.id,
      ...newAddress,
    };
    toast(addressId.data.message);
    dispatch(
      createOrder({
        data: { currency: "INR", receipt: "order_receipt_1", address },
        jwt,
        navigate,
      })
    );
    handleNext();
  };

  const handleCreateOrder = (item) => {
    const address = {
      id: selectedAddress?.id,
      first_name: item.first_name,
      last_name: item.last_name,
      street_address: item.street_address,
      city: item.city,
      state: item.state,
      zip_code: item.zip_code,
      mobile: item.mobile,
    };
    dispatch(
      createOrder({
        data: { currency: "INR", receipt: "order_receipt_1", address },
        jwt,
        navigate,
      })
    );
    handleNext();
  };

  return (
    <>
    <Grid container spacing={4}>
      <Grid item xs={12} lg={5}>
        <Box className="border rounded-md shadow-md h-[30.5rem] overflow-y-scroll">
          {auth.user?.address?.[0]?.map((item) => (
            <div
              key={item.id}
              onClick={() => setSelectedAdress(item)}
              className="p-5 py-7 border-b cursor-pointer"
            >
              <AddressCard address={item} />
              {selectedAddress?.id === item.id && (
                <Button
                  sx={{ mt: 2 }}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreateOrder(item)}
                >
                  Deliver Here
                </Button>
              )}
            </div>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Box className="border rounded-md shadow-md p-5">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="firstName"
                  name="first_name"
                  label="First Name"
                  fullWidth
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="lastName"
                  name="last_name"
                  label="Last Name"
                  fullWidth
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="address"
                  name="street_address"
                  label="Address"
                  fullWidth
                  autoComplete="shipping address"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  autoComplete="address-level2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="state"
                  name="state"
                  label="State/Province/Region"
                  fullWidth
                  autoComplete="address-level1"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="zip"
                  name="zip_code"
                  label="Zip / Postal code"
                  fullWidth
                  autoComplete="postal-code"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="phoneNumber"
                  name="mobile"
                  label="Phone Number"
                  fullWidth
                  autoComplete="tel"
                  type="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{ padding: ".9rem 1.5rem" }}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Deliver Here
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
    <Toaster />
    </>
  );
}
