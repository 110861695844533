import { useState, useEffect, Fragment } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { API_BASE_URL } from "../../../config/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  findProductById,
  updateProduct,
} from "../../../Redux/Customers/Product/Action";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const UpdateProductForm = () => {
  const [category, setCategory] = useState([]);
  const jwt = localStorage.getItem("jwt");
  const [productData, setProductData] = useState({
    Sq_id: "",
    title: "",
    description: "",
    price: null,
    discountedPrice: null,
    discountPercent: null,
    quantity: null,
    brand: "",
    color: "",
    imageUrl: "",
    image_url1: "",
    image_url2: "",
    image_url3: "",
    thirdLevelCategory: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const { customersProduct } = useSelector((store) => store);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericFields = [
      "price",
      "discountedPrice",
      "discountPercent",
      "quantity",
    ];

    let newValue = value;

    if (numericFields.includes(name)) {
      newValue = value === "" ? 0 : Number(value);
      if (isNaN(newValue)) {
        toast.error(`Invalid value for ${name}`);
        return;
      }
    }

    setProductData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateProduct(productData));
    toast.success("Product updated successfully");
    navigate("/admin/products");
  };

   useEffect(() => {
     if (productId) {
       dispatch(findProductById({ productId, jwt }));
     }
   }, [productId]);

  useEffect(() => {
    if (customersProduct.product) {
      const {
        id,
        Sq_id,
        title,
        description,
        brand,
        color,
        price,
        quantity,
        category_id,
        image_url,
        discounted_price,
        discount_percent,
        image_url1,
        image_url2,
        image_url3,
      } = customersProduct.product.content;
      setProductData({
        id,
        Sq_id,
        title,
        description,
        price,
        brand,
        color,
        quantity,
        thirdLevelCategory: category_id.toString() || "",
        imageUrl: image_url || "",
        discountedPrice: discounted_price || "",
        discountPercent: discount_percent || "",
        image_url1,
        image_url2,
        image_url3
      });
    }
  }, [customersProduct.product]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories/`);
      setCategory(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const getThirdLevelCategories = () => {
    const flattenCategories = (categories, level = 0) => {
      let result = [];
      categories.forEach((category) => {
        if (level === 2) {
          result.push(category);
        } else if (
          category.subcategories &&
          category.subcategories.length > 0
        ) {
          result = result.concat(
            flattenCategories(category.subcategories, level + 1)
          );
        }
      });
      return result;
    };
    return flattenCategories(category, 0);
  };

  const thirdLevelCategories = getThirdLevelCategories();

  return (
    <Fragment className="createProductContainer">
      <Typography variant="h3" sx={{ textAlign: "center" }} className="py-10">
        Update Product
      </Typography>
      <form onSubmit={handleSubmit} className="createProductContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Sq ID"
              name="Sq_id"
              value={productData.Sq_id}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image1 URL"
              name="imageUrl"
              value={productData.imageUrl}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image2 URL"
              name="image_url1"
              value={productData.image_url1}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image3 URL"
              name="image_url2"
              value={productData.image_url2}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image4 URL"
              name="image_url3"
              value={productData.image_url3}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Brand"
              name="brand"
              value={productData.brand}
              placeholder="Qala Karwaan"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              placeholder="Coffee Mugs"
              value={productData.title}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Color"
              name="color"
              placeholder="White"
              value={productData.color}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Quantity"
              name="quantity"
              value={productData.quantity}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={productData.price}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Discounted Price"
              name="discountedPrice"
              value={productData.discountedPrice}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth required>
              <InputLabel>Category</InputLabel>
              <Select
                name="thirdLevelCategory"
                value={productData.thirdLevelCategory}
                onChange={handleChange}
                label="Category"
              >
                {thirdLevelCategories.map((item) => (
                  <MenuItem key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              name="description"
              rows={3}
              onChange={handleChange}
              value={productData.description}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ p: 1.8 }}
              size="large"
              type="submit"
            >
              Update Product
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default UpdateProductForm;
