import React from "react";
import { useNavigate } from "react-router-dom";

const SimilarProduct = ({ product }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate(`/product/${product.id}`);
  };

  return (
    <div
      onClick={handleNavigate}
      className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden h-auto w-full sm:w-[12rem] mx-auto"
    >
      <div className="h-[12rem] max-h-[12rem] w-full">
        <img
          className="object-cover object-top w-full h-full"
          src={product?.image || product?.imageUrl || product?.image_url}
          alt={product?.title}
        />
      </div>

      <div className="p-4">
        <h3 className="text-lg font-medium text-gray-900">
          {product?.brand || product?.title}
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          {product?.title.length > 20
            ? `${product?.title.slice(0, 30)}...`
            : product?.title}
        </p>
        <div className="flex space-x-2 items-center">
          <p className="font-semibold">
            ₹{product?.discountedPrice || product?.discounted_price}
          </p>
          <p className="opacity-50 line-through">₹{product?.price}</p>
          <p className="text-green-600 font-semibold">
            {product?.discountPersent}% off
          </p>
        </div>
      </div>
    </div>
  );
};

export default SimilarProduct;
