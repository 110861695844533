import React, { useEffect, useState, Fragment } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { createProduct } from "../../../Redux/Customers/Product/Action";
import { API_BASE_URL } from "../../../config/api";
import "./CreateProductForm.css";
import toast from "react-hot-toast";

const initialProductData = {
  Sq_id: "",
  title: "",
  description: "",
  price: 0,
  discountedPrice: 0,
  discountPercent: 0,
  num_ratings: 0,
  quantity: 0,
  brand: "",
  color: "",
  imageUrl: "",
  image_url1: "",
  image_url2: "",
  image_url3: "",
  thirdLevelCategory: "",
};

const CreateProductForm = () => {
  const [category, setCategory] = useState([]);
  const [productData, setProductData] = useState(initialProductData);
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevState) => {
      const updatedData = { ...prevState, [name]: value };

      // Convert price and discountedPrice to numbers
      const price = parseFloat(updatedData.price) || 0;
      const discountedPrice = parseFloat(updatedData.discountedPrice) || 0;

      // Calculate discount percentage if price and discountedPrice are valid
      if (name === "price" || name === "discountedPrice") {
        if (price > 0 && discountedPrice > 0 && discountedPrice < price) {
          const discount = ((price - discountedPrice) / price) * 100;
          updatedData.discountPercent = Math.round(discount);
        } else {
          updatedData.discountPercent = 0; // Reset if conditions are not met
        }
      }

      return updatedData;
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(createProduct({ data: productData, jwt })).then(() => {
        toast.success("Product created successfully!");
        setProductData(initialProductData);
      });
    } else {
      toast.error("Please fill all required fields.");
    }
  };

  // Form validation to check required fields
  const validateForm = () => {
    const requiredFields = [
      "Sq_id",
      "title",
      "description",
      "price",
      "discountedPrice",
      "quantity",
      "brand",
      "color",
      "imageUrl",
      "image_url1",
      "image_url2",
      "image_url3",
      "thirdLevelCategory",
    ];

    for (let field of requiredFields) {
      if (!productData[field]) {
        return false;
      }
    }
    return true;
  };

  // Fetch categories
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories/`);
      setCategory(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Flatten nested categories to get third-level categories
  const getThirdLevelCategories = () => {
    const flattenCategories = (categories, level = 0) => {
      let result = [];
      categories.forEach((category) => {
        if (level === 2) {
          result.push(category);
        } else if (
          category.subcategories &&
          category.subcategories.length > 0
        ) {
          result = result.concat(
            flattenCategories(category.subcategories, level + 1)
          );
        }
      });
      return result;
    };

    return flattenCategories(category, 0);
  };

  const thirdLevelCategories = getThirdLevelCategories();

  return (
    <Fragment className="createProductContainer">
      <Typography variant="h3" sx={{ textAlign: "center" }} className="py-10">
        Add New Product
      </Typography>
      <form onSubmit={handleSubmit} className="createProductContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Sq ID"
              name="Sq_id"
              value={productData.Sq_id}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image1 URL"
              name="imageUrl"
              value={productData.imageUrl}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image2 URL"
              name="image_url1"
              value={productData.image_url1}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image3 URL"
              name="image_url2"
              value={productData.image_url2}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Image4 URL"
              name="image_url3"
              value={productData.image_url3}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Brand"
              name="brand"
              value={productData.brand}
              placeholder="Qala Karwaan"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              placeholder="Coffee Mugs"
              value={productData.title}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Color"
              name="color"
              placeholder="White"
              value={productData.color}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Quantity"
              name="quantity"
              value={productData.quantity}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={productData.price}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Discounted Price"
              name="discountedPrice"
              value={productData.discountedPrice}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth required>
              <InputLabel>Category</InputLabel>
              <Select
                name="thirdLevelCategory"
                value={productData.thirdLevelCategory}
                onChange={handleChange}
                label="Category"
              >
                {thirdLevelCategories.map((item) => (
                  <MenuItem key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              name="description"
              rows={3}
              onChange={handleChange}
              value={productData.description}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ p: 1.8 }}
              size="large"
              type="submit"
            >
              Add New Product
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default CreateProductForm;
