import React, { useContext } from "react";
import "./Offers.css";
import { Link } from "react-router-dom";

const Offers = () => {
  return (
    <Link className="link" to={`/offers`}>
      <div className="offers">
        <div className="offers-left">
          <h1 className={`h1_light`}>Exclusive</h1>
          <h1 className={`h1_light`}>Offers For You</h1>
          <p className={`p_light`}>ONLY ON BEST SELLERS PRODUCTS</p>
          <button>Check Now</button>
        </div>
        <div className="offers-right">
          <img
            src="https://m.media-amazon.com/images/I/41j+CDXVkNL._SY300_SX300_.jpg"
            alt=""
          />
        </div>
      </div>
    </Link>
  );
};

export default Offers;
