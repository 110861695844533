import React from "react";
import { useNavigate } from "react-router-dom";

const HomeProductCard = ({ product }) => {
  const navigate = useNavigate();

  // Fallbacks for product image and title
  const imageUrl = product?.image || product?.imageUrl || product?.image_url;
  const productTitle = product?.title || "No Title Available";
  const productBrand = product?.brand || product?.title || "Unknown Brand";

  return (
    <div
      onClick={() => navigate(`/Home Appliances/Cups/Ceramic Cups`)}
      className="cursor-pointer flex flex-col items-center bg-white rounded-xl shadow-lg overflow-hidden mx-2 mb-4 transition-transform transform hover:scale-105"
      style={{ width: "100%", maxWidth: "18rem" }} // Makes the card responsive with a max width
    >
      {/* Image Section */}
      <div className="h-[13rem] w-full bg-gray-100 flex items-center justify-center">
        <img
          className="object-contain w-full h-full p-4"
          src={imageUrl}
          alt={productTitle}
        />
      </div>

      {/* Content Section */}
      <div className="p-2 w-full">
        <h3 className="text-lg font-semibold text-gray-800 truncate">
          {productBrand}
        </h3>
        <p className="mt-1 text-sm text-gray-500 truncate">{productTitle}</p>

        {/* Price and Discount Section */}
        <div className="mt-1 flex items-center justify-between">
          <p className="font-semibold text-gray-900">
            ₹{product?.discountedPrice || product?.discounted_price || 0}
          </p>
          <div className="flex items-center space-x-2">
            {product?.price && (
              <p className="text-sm text-gray-400 line-through">
                ₹{product.price}
              </p>
            )}
            {product?.discount_percent && (
              <p className="text-sm text-green-600 font-semibold">
                {product?.discount_percent}% off
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProductCard;
