import React from "react";
import "./ProductCard.css";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ product }) => {
  const {
    title,
    brand,
    image_url,
    price,
    discounted_price,
    color,
    discount_percent,
  } = product;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/product/${product?.id}`);
  };

  return (
    <div
      onClick={handleNavigate}
      className="productCard border transition-all cursor-pointer"
    >
      <div className="h-auto">
        <img
          className="h-[12rem] sm:h-[14rem] w-full object-cover object-left-top"
          src={image_url}
          alt=""
        />
      </div>
      <div className="textPart bg-white p-3">
        <div>
          <p className="font-bold opacity-60">{brand}</p>
          <p>{title.length > 20 ? `${title.slice(0, 30)}...` : title}</p>
          <p className="font-semibold opacity-50">{color}</p>
        </div>
        <div className="flex space-x-2 items-center">
          <p className="font-semibold">₹{discounted_price}</p>
          <p className="opacity-50 line-through">₹{price}</p>
          <p className="text-green-600 font-semibold">
            {discount_percent}% off
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
