import React, { useEffect } from "react";
import CartItem from "./CartItem";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../../Redux/Customers/Cart/Action";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getCart(jwt));
  }, [jwt, dispatch]);

  // if (!cart.cart || !cart.cartItems) {
  //   return <p>Loading...</p>;
  // }

  return (
    <div className="">
      {cart.cartItems.length > 0 ? (
        <div className="lg:grid grid-cols-3 lg:px-16 relative">
          <div className="lg:col-span-2 lg:px-5 bg-white">
            <div className="space-y-3">
              {cart.cartItems.map((item) => (
                <CartItem item={item} showButton={true} />
              ))}
            </div>
          </div>
          <div className="px-5 sticky top-0 h-auto mt-5 lg:mt-0">
            <div className="border p-5 bg-white shadow-lg rounded-md">
              <p className="font-bold opacity-60 pb-4">PRICE DETAILS</p>
              <hr />
              <div className="space-y-3 font-semibold">
                <div className="flex justify-between pt-3 text-black">
                  <span>Price ({cart.cart.totalItem} item)</span>
                  <span>₹{cart.cart.totalPrice}</span>
                </div>
                <div className="flex justify-between">
                  <span>Discount</span>
                  <span className="text-green-700">-₹{cart.cart.discount}</span>
                </div>
                <div className="flex justify-between">
                  <span>Delivery Charges</span>
                  <span className="text-green-700">Free</span>
                </div>
                <hr />
                <div className="flex justify-between font-bold text-lg">
                  <span>Total Amount</span>
                  <span className="text-green-700">
                    ₹{cart.cart.totalDiscountedPrice}
                  </span>
                </div>
              </div>
              <Button
                onClick={() => navigate("/checkout?step=2")}
                variant="contained"
                sx={{ padding: ".8rem 2rem", marginTop: "2rem", width: "100%" }}
              >
                Check Out
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div class="flex flex-col items-center justify-center">
          <div class="bg-white relative">
            <img
              src="https://qalakarwaan.in/images/CART.png"
              alt="Empty Cart"
              class="w-64 h-auto mb-8 mix-blend-multiply block"
            />
          </div>

          <h2 class="text-2xl font-semibold text-gray-800 mb-4">
            Your Cart is Empty
          </h2>
          <p class="text-lg text-gray-600 mb-6">
            It looks like you haven’t added anything to your cart yet.
          </p>
          <a
            href="/"
            class="px-6 py-3 bg-[#2C3E50] text-white text-lg rounded-lg hover:bg-[#43596e] transition duration-300"
          >
            Start Shopping
          </a>
        </div>
      )}
    </div>
  );
};

export default Cart;
