export const navigation = {
  categories: [
    {
      id: "departments",
      name: "Departments",
      featured: [
        {
          name: "New Arrivals",
          href: "/",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg",
          imageAlt:
            "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Basic Tees",
          href: "/",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg",
          imageAlt:
            "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
        },
      ],
      sections: [
        {
          id: "bedroom",
          name: "Bedroom",
          items: [
            {
              name: "Block Print Bedsheet",
              id: "blockprintbedsheet",
              href: `/departments/bedroom/block print bedsheet`,
              img: "",
            },
            {
              name: "Plan Bedsheet",
              id: "planbedsheet",
              href: `/departments/bedroom/plan bedsheet`,
              img: "",
            },
            {
              name: "Silk Bedsheet",
              id: "silkbedsheet",
              href: `/departments/bedroom/silk bedsheet`,
              img: "",
            },
            {
              name: "Screenprint Bedsheet",
              id: "screenprintbedsheet",
              href: `/departments/bedroom/screenprint bedsheet`,
              img: "",
            },
            {
              name: "Side Lamps",
              id: "sidelamps",
              href: `/departments/bedroom/side lamps`,
              img: "",
            },
          ],
        },
        {
          id: "livingroom",
          name: "Living Room Decor",
          items: [
            {
              name: "Marmor Ceramic Vase",
              id: "marmorceramicvase",
              href: `/departments/livingroom/marmor cermaic vase`,
              img: "",
            },
            {
              name: "Donut Vase",
              id: "donutvase",
              href: `/departments/livingroom/donut vase`,
              img: "",
            },
            {
              name: "Vase",
              id: "vase",
              href: `/departments/livingroom/vase`,
              img: "",
            },
            {
              name: "Lamps",
              id: "lamps",
              href: `/departments/livingroom/lamps`,
              img: "",
            },
            {
              name: "Cushions",
              id: "cushions",
              href: `/departments/livingroom/cushions`,
              img: "",
            },
            {
              name: "Rugs",
              id: "rugs",
              href: `/departments/livingroom/rugs`,
              img: "",
            },
          ],
        },
        {
          id: "kitchen",
          name: "Kitchen",
          items: [
            {
              name: "Kitchen Bowl",
              id: "kitchenbowl",
              href: `/departments/kitchen/kitchen bowl`,
              img: "",
            },
            {
              name: "Dinner Set",
              id: "dinnerset",
              href: `/departments/kitchen/dinner set`,
              img: "",
            },
            {
              name: "Air Tight Jars",
              id: "airtightjars",
              href: `/departments/kitchen/air tight jars`,
              img: "",
            },
            {
              name: "Jars",
              id: "jars",
              href: `/departments/kitchen/jars`,
              img: "https://m.media-amazon.com/images/I/81hRYUuOUXL._AC_UY327_QL65_.jpg",
            },
            {
              name: "Tea Cup Set",
              id: "teacupset",
              href: `/departments/kitchen/tea cup set`,
              img: "https://m.media-amazon.com/images/I/61fiTSmGBEL._AC_UY327_QL65_.jpg",
            },
            {
              name: "Coffee Mugs",
              id: "coffeemugs",
              href: `/departments/kitchen/coffee mugs`,
              img: "https://m.media-amazon.com/images/I/71yrzSVTwqL._AC_UY327_QL65_.jpg",
            },
            {
              name: "Milk Mug",
              id: "milkmug",
              href: `/departments/kitchen/milk mug`,
              img: "",
            },
            {
              name: "Salad Bowl",
              id: "saladbowl",
              href: `/departments/kitchen/salad bowl`,
              img: "",
            },
            {
              name: "Salad Plates",
              id: "saladplates",
              href: `/departments/kitchen/salad plates`,
              img: "",
            },
            {
              name: "Starter Plates",
              id: "starterplates",
              href: `/departments/kitchen/starter plates`,
              img: "",
            },
          ],
        },
        {
          id: "bathroom",
          name: "Bathroom",
          items: [
            {
              name: "Bathroom Dispenser",
              id: "bathroomdispenser",
              href: `/departments/bathroom/bathroom dispenser`,
              img: "https://m.media-amazon.com/images/I/71pODOozAWL._AC_UY327_FMwebp_QL65_.jpg",
            },
          ],
        },
      ],
    },
    // {
    //   id: "livingroom",
    //   name: "Living Room",
    //   featured: [
    //     {
    //       name: "New Arrivals",
    //       id: "#",
    //       imageSrc:
    //         "https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg",
    //       imageAlt:
    //         "Drawstring top with elastic loop closure and textured interior padding.",
    //     },
    //     {
    //       name: "Artwork Tees",
    //       id: "#",
    //       imageSrc:
    //         "https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg",
    //       imageAlt:
    //         "Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.",
    //     },
    //   ],
    //   sections: [
    //     {
    //       id: "clothing",
    //       name: "Clothing",
    //       items: [
    //         { name: "Mens Kurtas", id: "mens_kurta" },
    //         { name: "Shirt", id: "shirt" },
    //         { name: "Men Jeans", id: "men_jeans" },
    //         { name: "Sweaters", id: "#" },
    //         { name: "T-Shirts", id: "t-shirt" },
    //         { name: "Jackets", id: "#" },
    //         { name: "Activewear", id: "#" },
    //       ],
    //     },
    //     {
    //       id: "accessories",
    //       name: "Accessories",
    //       items: [
    //         { name: "Watches", id: "#" },
    //         { name: "Wallets", id: "#" },
    //         { name: "Bags", id: "#" },
    //         { name: "Sunglasses", id: "#" },
    //         { name: "Hats", id: "#" },
    //         { name: "Belts", id: "#" },
    //       ],
    //     },
    //     {
    //       id: "brands",
    //       name: "Brands",
    //       items: [
    //         { name: "Re-Arranged", id: "#" },
    //         { name: "Counterfeit", id: "#" },
    //         { name: "Full Nelson", id: "#" },
    //         { name: "My Way", id: "#" },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "kitchen",
    //   name: "Kitchen & Dining",
    //   featured: [
    //     {
    //       name: "New Arrivals",
    //       href: "/Home Appliances/Cups/Ceramic Cups",
    //       imageSrc:
    //         "https://m.media-amazon.com/images/I/71wDcNm-JVL._SL1500_.jpg",
    //       imageAlt:
    //         "Models sitting back to back, wearing Basic Tee in black and bone.",
    //     },
    //     {
    //       name: "Basic Tees",
    //       href: "/",
    //       imageSrc:
    //         "https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg",
    //       imageAlt:
    //         "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
    //     },
    //   ],
    //   sections: [
    //     {
    //       id: "Cups",
    //       name: "Cups",
    //       items: [
    //         {
    //           name: "Ceramic Cups",
    //           id: "Ceramic Cups",
    //           href: `{Home Appliance/Cups/Ceramic Cups}`,
    //         },
    //       ],
    //     },
    //     {
    //       id: "accessories",
    //       name: "Accessories",
    //       items: [
    //         { name: "Watches", id: "watch" },
    //         { name: "Wallets", id: "wallet" },
    //         { name: "Bags", id: "bag" },
    //         { name: "Sunglasses", id: "sunglasse" },
    //         { name: "Hats", id: "hat" },
    //         { name: "Belts", id: "belt" },
    //       ],
    //     },
    //     {
    //       id: "brands",
    //       name: "Brands",
    //       items: [
    //         { name: "Full Nelson", id: "#" },
    //         { name: "My Way", id: "#" },
    //         { name: "Re-Arranged", id: "#" },
    //         { name: "Counterfeit", id: "#" },
    //         { name: "Significant Other", id: "#" },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "bathroom",
    //   name: "Bathroom",
    //   featured: [
    //     {
    //       name: "New Arrivals",
    //       href: "/Home Appliances/Cups/Ceramic Cups",
    //       imageSrc:
    //         "https://m.media-amazon.com/images/I/71wDcNm-JVL._SL1500_.jpg",
    //       imageAlt:
    //         "Models sitting back to back, wearing Basic Tee in black and bone.",
    //     },
    //     {
    //       name: "Basic Tees",
    //       href: "/",
    //       imageSrc:
    //         "https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg",
    //       imageAlt:
    //         "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
    //     },
    //   ],
    //   sections: [
    //     {
    //       id: "Cups",
    //       name: "Cups",
    //       items: [
    //         {
    //           name: "Ceramic Cups",
    //           id: "Ceramic Cups",
    //           href: `{Home Appliance/Cups/Ceramic Cups}`,
    //         },
    //       ],
    //     },
    //     {
    //       id: "accessories",
    //       name: "Accessories",
    //       items: [
    //         { name: "Watches", id: "watch" },
    //         { name: "Wallets", id: "wallet" },
    //         { name: "Bags", id: "bag" },
    //         { name: "Sunglasses", id: "sunglasse" },
    //         { name: "Hats", id: "hat" },
    //         { name: "Belts", id: "belt" },
    //       ],
    //     },
    //     {
    //       id: "brands",
    //       name: "Brands",
    //       items: [
    //         { name: "Full Nelson", id: "#" },
    //         { name: "My Way", id: "#" },
    //         { name: "Re-Arranged", id: "#" },
    //         { name: "Counterfeit", id: "#" },
    //         { name: "Significant Other", id: "#" },
    //       ],
    //     },
    //   ],
    // },
  ],
  pages: [
    { name: "Orders", id: "/" },
    { name: "Profile", id: "/" },
  ],
};
