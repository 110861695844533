import axios from "axios";
import {
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_ORDER_BY_ID_FAILURE,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_HISTORY_FAILURE,
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
} from "./ActionType";
import api, { API_BASE_URL } from "../../../config/api";

export const createOrder = (reqData) => async (dispatch) => {
  console.log("Request data: ", reqData);
  try {
    dispatch({ type: CREATE_ORDER_REQUEST });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${reqData.jwt}`,
    };

    const { data } = await axios.post(
      `${API_BASE_URL}/api/create_order/`,
      reqData.data,
      { headers }
    );

    const { data: additionalData } = await api.get(`${API_BASE_URL}/api/orders/${data.id}`, { headers });

    if (data.id) {
      reqData.navigate({ search: `step=3&order_id=${data.id}` });
    }

    const combinedData = {
      ...data,
      order: additionalData,
    };

    console.log("Order creation response: ", combinedData);

    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: combinedData,
    });
  } catch (error) {
    console.error(
      "Error creating order: ",
      error.response ? error.response.data : error.message
    );
    dispatch({
      type: CREATE_ORDER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getOrderById = (reqData) => async (dispatch) => {
  console.log("get order req ", reqData.orderId);
  try {
    dispatch({ type: GET_ORDER_BY_ID_REQUEST });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${reqData.jwt}`,
    };

    const { data } = await api.get(
      `${API_BASE_URL}/api/orders/${reqData.orderId}`, { headers }
    );
    console.log("order by id ", data);
    dispatch({
      type: GET_ORDER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("catch ",error)
    dispatch({
      type: GET_ORDER_BY_ID_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOrderHistory = (reqData) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ORDER_HISTORY_REQUEST });
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${reqData.jwt}`,
    };

    const { data } = await api.get(`${API_BASE_URL}/api/orders/user/`, {
      headers,
    });
    console.log("order history -------- ", data);
    dispatch({
      type: GET_ORDER_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ORDER_HISTORY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};