import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ProductDetails from "../customer/Components/Product/ProductDetails/ProductDetails";
import Product from "../customer/Components/Product/Product/Product";
import Contact from "../Pages/Contact";
import TearmsCondition from "../Pages/TearmsCondition";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import About from "../Pages/About";
import Homepage from "../Pages/Homepage";
import Navigation from "../customer/Components/Navbar/Navigation";
import Cart from "../customer/Components/Cart/Cart";
import { ThemeProvider } from "@mui/material/styles";
import { customerTheme } from "../Admin/them/customeThem";
import Order from "../customer/Components/orders/Order";
import OrderDetails from "../customer/Components/orders/OrderDetails";
import Checkout from "../customer/Components/Checkout/Checkout";
import Footer from "../customer/Components/footer/Footer";
import PaymentSuccess from "../customer/Components/paymentSuccess/PaymentSuccess";
import RateProduct from "../customer/Components/ReviewProduct/RateProduct";
import NotFound from "../Pages/Notfound";

const CustomerRoutes = () => {
  const location = useLocation();

  // Define paths where the navigation bar should not be shown
  const hideNavigationPaths = ["/login", "/register", "*"];
  const showNavigation = !hideNavigationPaths.includes(location.pathname);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <ThemeProvider theme={customerTheme}>
        {/* {showNavigation && ( */}
        <Navigation
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        />
        {/* )} */}

        <div
          style={{ flexGrow: 1, paddingTop: showNavigation ? "130px" : "0" }}
        >
          <Routes>
            <Route path="/login" element={<Homepage />} />
            <Route path="/register" element={<Homepage />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/home" element={<Homepage />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-condition" element={<TearmsCondition />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/:lavelOne/:lavelTwo/:lavelThree"
              element={<Product />}
            />
            <Route path="/search" element={<Product />} />
            <Route path="/product/:productId" element={<ProductDetails />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/account/order" element={<Order />} />
            <Route path="/account/order/:orderId" element={<OrderDetails />} />
            <Route path="/account/rate/:productId" element={<RateProduct />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment/callback" element={<PaymentSuccess />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>

        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default CustomerRoutes;
