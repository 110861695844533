import React from "react";
import { Link } from "react-router-dom";

// Updated href and image URLs from Unsplash
export const bestSellersData = [
  {
    name: "Vase",
    image: "https://qalakarwaan.in/images/bestsellers/Vase.jpg",
    price: 19.99,
    href: "/departments/livingroom/vase",
  },
  {
    name: "Block Print Bedsheet",
    image: "https://qalakarwaan.in/images/bestsellers/blockPrintBedsheet.webp",
    price: 29.99,
    href: "/departments/bedroom/block%20print%20bedsheet",
  },
  {
    name: "Handmade Coffee Mugs",
    image: "https://qalakarwaan.in/images/bestsellers/coffeeMugs.jpg",
    price: 799,
    href: "/departments/kitchen/coffee%20mugs",
  },
  {
    name: "Bathroom Dispenser",
    image: "https://qalakarwaan.in/images/bestsellers/bathroomDispenser.jpg",
    price: 799,
    href: "/departments/bathroom/bathroom%20dispenser",
  },
  {
    name: "Cushions",
    image: "https://qalakarwaan.in/images/bestsellers/cushions.jpg",
    price: 799,
    href: "/departments/livingroom/cushions",
  },
  {
    name: "Candles",
    image: "https://qalakarwaan.in/images/bestsellers/candles.jpg",
    price: 799,
    href: "/departments/bedroom/silkbedsheet",
  },
];

const BestSellers = () => {
  return (
    <div className="px-4">
      <h2 className="text-3xl font-bold ml-4">Best Sellers</h2>
      <div className="flex flex-wrap justify-center mt-5">
        {bestSellersData.map((product) => (
          <Link
            to={product.href}
            key={product.name}
            className="w-full w-1/2 sm:w-1/2 md:w-1/3 p-4 flex flex-col items-center text-center cursor-pointer transform transition-transform duration-300 hover:scale-105"
          >
            <img
              src={product.image}
              alt={product.name}
              className="w-full max-h-[250px] sm:h-[300px] sm:max-h-[300px] object-cover rounded-lg"
            />
            <p className="mt-3 text-lg font-semibold">{product.name}</p>
            {/* <p className="mt-1 text-md">${product.price.toFixed(2)}</p> */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
